import React, { useRef } from 'react'
import { Team } from '../Team'

import './About.css'

import arrowlong from '../../assets/images/arrow-long.svg';

import fb from '../../assets/images/social1.svg';
import twitter from '../../assets/images/social2.svg';
import insta from '../../assets/images/social3.svg';
import profile from '../../assets/images/profile.png';
import profilee from '../../assets/images/profile2.png';
import tejinder from '../../assets/images/tejinder1.jpg' 
import kunal from '../../assets/images/kunal1.jpg'
import dummy from '../../assets/images/profile4.png';
import { Link } from 'react-router-dom';
import Testimonial from './Testinomial';
import logo from '../../assets/images/24klogo.svg';
const About = () => {
  const scollToRef = useRef();
  return (
    <div className='meet-our-team-page'>
      <div className='heade-for-inner-listing header-about-inner'>
        <div className='black-overlay'></div>
        <div className='container-main container-main-about-sec-tw inner-header-cntnt second-sec-txt-wrap'>
          <div className='banner-block-text'>
            <h2>Meet Our Team</h2>
            <p>24k Realty Group is a multifaceted corporate entity providing services that cover Real Estate, Mortgages, Insurance, Financing (private lending) and Land Development.</p>
          </div>
        </div>
      </div>

      <div className="meet-team-sec">
        <div className="container-main container-main-about-sec-tw">
          <div className="team-container-wrapper team-individual-container-wrapper">

            <div className="team-card-container img-main-left">
              <img src={kunal} />
            </div>

            <div className="team-left-content">
              <h2>Know more about us</h2>
              <p className='highlited-text'>24k Realty Group</p>
              <p>
                24k Realty Group is a multifaceted corporate entity providing services that cover Real Estate, Mortgages, Insurance, Financing (private lending) and Land Development. Founded in 2021 by father and sons Bharat, Kunal and Dev Bharatendu, the company strives to interlink its services to provide a holistic solution to client needs.
              </p>

              <p>Kunal Bharatendu is the founder of 24k Realty Group and the leader of the Real Estate and Land Development divisions. His professional history spans various industries and occupations. Most notably, he is a registered engineer with the EGBC association. He has led and coordinated projects for Telus and BC Hydro, during which he solidified relationships with clientele across the region making his transition to real estate a smooth process. His connections with the telecommunication and engineering industry situate him in a unique position to leverage deals for his clients. As a result, the past five years have seen his meteoric rise to the top 10% amongst all realtors in the lower mainland, no easy feat indeed.</p>
              <p>When discussing his history and the stages of his life that brought him to this point, he reminisces about arriving in Canada during the 2008 recession. Despite excelling at his engineering studies in India he was unable to find any work without Canadian certification and when he finally did land a job, it was at a Honda dealership as a Car Salesman. Undeterred, he worked on getting his engineering credentials alongside his job and earned his designation.</p>
              <Link onClick={() => scollToRef.current.scrollIntoView()} className="meet-our-team-btn-about button-arrow btn-yellow">
                Meet our team <img className='arrow-n-btn block-arrow arow-know-more' src={arrowlong} alt="My Image" />
              </Link>
            </div>
          </div>

          <div className="team-container-wrapper team-individual-container-wrapper about-sec-middle-sec">
            <div className="team-left-content team-left-content-columns">
              <p>
                Reflecting on this time, Kunal speaks about the misunderstood plight of immigrants coming to Canada in search of a better life and the many obstacles they face in pursuit of this dream. He says that a career in Real Estate has been fulfilling because he is in the position to realize their desire for a dream home. At times he admittedly gets emotionally attached to certain clients in their quest for the ideal home, using his identification with the immigrant struggle as a source of motivation and drive.
              </p>

              <p>
                His commitment to uplifting his community does not end there. Since 2017, Kunal has been an active member of the society of Punjabi Engineers and Technologists of BC (speatbc.org). This non-profit organization brings together industry professionals to offer mentorship for the next generation of bright young minds, as well as hosting community initiatives such as fundraising for scholarships, blood drives and more. In three short years, Kunal rose to the honor of being president of the organization, offering his leadership during the unprecedented Covid-19 pandemic.
              </p>
            </div>

            <div className="team-card-container team-card-container-columns">
              <img src='https://img.freepik.com/free-photo/portrait-multi-ethnic-business-team_155003-14797.jpg?w=740&t=st=1696494539~exp=1696495139~hmac=69edb3f76e96f84d7df3acbaeb57947357cfd974be41a6f60d63f4a897f5f106' />
            </div>
          </div>

          <div className="team-container-wrapper team-individual-container-wrapper">
            <div className="team-card-container">
              <img src='https://img.freepik.com/free-photo/happy-real-estate-agent-giving-keys-couple-who-is-buying-their-new-home_637285-6093.jpg?w=740&t=st=1696498017~exp=1696498617~hmac=7b75f1e5794e42e6faac1b20c5c15490fa55d4de27d52c421cc0c3c1810c5f9f' />
            </div>
            <div className="team-left-content">
              <p>
                Clients rave about Kunal’s precision and detail oriented work, no doubt the signs of an engineer’s mind, but he says the most important value he adopted from his time in that profession is an ethical mindset, integrity and a dedication to a transparent, communicative workflow.
              </p>

              <p>
                When asked where he sees himself and 24k Realty Group in 5 years from now, Kunal does not mince his words: “We are not here to build around me, we are here to create collaboration. We want to give people an opportunity to grow at their own pace, allowing this group to host a diversified set of talents all under one roof.”
              </p>
              <Link to='/our-listing' className="button-arrow btn-yellow">
                View Listings<img className='arrow-n-btn block-arrow arow-know-more' src={arrowlong} alt="My Image" />
              </Link>
            </div>
          </div>

        </div>
      </div>

      <div className="meet-team-sec meet-our-team-on-page meet-our-team-on-page-indiv" ref={scollToRef}>
        <div className="container-main">
          <div className='second-sec-txt-wrap'>
            <h2>Meet Our Team</h2>
            <p>Here is my team who have realy Good experience in this field.</p>
          </div>

          {/* <div className="team-container-wrapper">
            <div className="team-card-container team-card-container-indi">
              <div className="card">
                <div className="imgBx">
                  <img
                    src={profile}
                    alt=""
                  />
                </div>
                <div className="content">
                  <div className="contentBx">
                    <h3>
                      Kunal Bharatendu<br />
                      <span>Founder</span>
                    </h3>
                  </div>
                  <ul className="sci">
                    <li>
                      <a href="#">
                        <img className='' src={fb} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={twitter} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={insta} alt="My Image" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card">
                <div className="imgBx">
                  <img
                    src={profilee}
                    alt=""
                  />
                </div>
                <div className="content">
                  <div className="contentBx">
                    <h3>
                      Tejinder Punia <br />
                      <span>Realtor</span>
                    </h3>
                  </div>
                  <ul className="sci">
                    <li>
                      <a href="#">
                        <img className='' src={fb} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={twitter} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={insta} alt="My Image" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="card">
                <div className="imgBx">
                  <img
                    src={profileee}
                    alt=""
                  />
                </div>
                <div className="content">
                  <div className="contentBx">
                    <h3>
                      Vishal Gupta<br />
                      <span>Realtor</span>
                    </h3>
                  </div>
                  <ul className="sci">
                    <li>
                      <a href="#">
                        <img className='' src={fb} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={twitter} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={insta} alt="My Image" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div> */}

          {/* <div className="team-container-wrapper">
            <div className="team-card-container team-card-container-indi profile-cards-container">
              <div className="profile-card-main">
                <div className="imgBx">
                  <img
                    src={profile}
                    alt=""
                  />
                </div>
                <div className='social-icons'>
                  <ul className="sci">
                      <li>
                        <a href="#">
                          <img className='' src={fb} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={twitter} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={insta} alt="My Image" />
                        </a>
                      </li>
                    </ul>
                </div>
                <div className='profile-content'>
                  <h2>Kunal Bharattendu</h2>
                  <span>Founder</span>
                </div>
              </div>

              <div className="profile-card-main">
                <div className="imgBx">
                  <img
                    src={profilee}
                    alt=""
                  />
                </div>
                <div className='social-icons'>
                  <ul className="sci">
                      <li>
                        <a href="#">
                          <img className='' src={fb} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={twitter} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={insta} alt="My Image" />
                        </a>
                      </li>
                    </ul>
                </div>
                <div className='profile-content'>
                  <h2>Venkata Kommineni </h2>
                  <span>Managing Partner</span>
                </div>
                
              </div>

              <div className="profile-card-main">
                <div className="imgBx">
                  <img
                    src={logo}
                    alt=""
                  />
                </div>
                <div className='social-icons'>
                  <ul className="sci">
                      <li>
                        <a href="#">
                          <img className='' src={fb} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={twitter} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={insta} alt="My Image" />
                        </a>
                      </li>
                    </ul>
                </div>
                <div className='profile-content'>
                  <h2>coming soon </h2>
                  <span>Realtor</span>
                </div>
                
              </div>

              <div className="profile-card-main">
                <div className="imgBx">
                  <img
                    src={logo}
                    alt=""
                  />
                </div>
                <div className='social-icons'>
                  <ul className="sci">
                      <li>
                        <a href="#">
                          <img className='' src={fb} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={twitter} alt="My Image" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <img className='' src={insta} alt="My Image" />
                        </a>
                      </li>
                    </ul>
                </div>
                <div className='profile-content'>
                  <h2>coming soon </h2>
                  <span>Administrative Assistant</span>
                </div>
                
              </div>

              
            </div>
          </div> */}

          <div className="team-container-wrapper">
            <div className="team-card-container team-card-container-indi">
              <div className="card">
                <div className="imgBx">
                  <img
                    src={kunal}
                    alt=""
                  />
                </div>
                <div className="content">
                  <div className="contentBx">
                    <h3>
                      Kunal Bharatendu<br />
                      <span>Founder</span>
                    </h3>
                  </div>
                  <ul className="sci">
                    <li>
                      <a href="#">
                        <img className='' src={fb} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={twitter} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={insta} alt="My Image" />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="contentBx">
                  <h3>
                    Kunal Bharatendu<br />
                    <span>Founder</span>
                  </h3>
                </div>
              </div>

              <div className="card">
                <div className="imgBx">
                  <img
                    src={tejinder}
                    alt=""
                  />
                </div>
                <div className="content">
                  <div className="contentBx">
                    <h3>
                      Tejinder Punia <br />
                      <span>Realtor</span>
                    </h3>
                  </div>
                  <ul className="sci">
                    <li>
                      <a href="#">
                        <img className='' src={fb} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={twitter} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={insta} alt="My Image" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contentBx">
                  <h3>
                    Tejinder Punia <br />
                    <span>Realtor</span>
                  </h3>
                </div>
              </div>

              <div className="card">
                <div className="imgBx">
                  <img
                    src="./team-m-f.jpg"
                    alt=""
                  />
                </div>
                <div className="content">
                  <div className="contentBx">
                    <h3>
                      coming soon<br />
                      <span>Realtor</span>
                    </h3>
                  </div>
                  <ul className="sci">
                    <li>
                      <a href="#">
                        <img className='' src={fb} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={twitter} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={insta} alt="My Image" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contentBx">
                  <h3>
                    coming soon<br />
                    <span>Realtor</span>
                  </h3>
                </div>
              </div>

              <div className="card">
                <div className="imgBx">
                  <img
                    src="./team-m-l.jpg"
                    alt=""
                  />
                </div>
                <div className="content">
                  <div className="contentBx">
                    <h3>
                      coming soon<br />
                      <span>Administrative Assistant</span>
                    </h3>
                  </div>
                  <ul className="sci">
                    <li>
                      <a href="#">
                        <img className='' src={fb} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={twitter} alt="My Image" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <img className='' src={insta} alt="My Image" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="contentBx">
                  <h3>
                    coming soon<br />
                    <span>Administrative Assistant</span>
                  </h3>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div className="meet-team-sec testimonial-section">
        <div className="container-main">
          <div className='second-sec-txt-wrap'>
            <h2>What our client's Says</h2>
            <p>See client's Testimonials</p>

          </div>

          <div className="teastimonial-wrapper">
            <Testimonial />
          </div>
        </div>
      </div>
    </div>
  )
}

export default About