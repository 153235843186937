import React, { useState,useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Link } from 'react-router-dom'
import "./ListingSingle.scss"
import Form from 'react-bootstrap/Form';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Button from 'react-bootstrap/Button';

import bed from '../../assets/images/bed.svg';
import paper from '../../assets/images/paper.svg';
import area from '../../assets/images/area.svg';
import location from '../../assets/images/location.svg';
import locationWhite from '../../assets/images/location_white.svg';
import scale from '../../assets/images/scale.svg';
import print from '../../assets/images/print.svg';
import share from '../../assets/images/share.svg';
import like from '../../assets/images/like.svg';
import calendar from '../../assets/images/calendar.svg';
import avatar from '../../assets/images/avatar.svg';
import arrowlong from '../../assets/images/arrow-long.svg';
import walk from '../../assets/images/walk.svg';
import back from '../../assets/images/arrow-back.svg';
import bus from '../../assets/images/bus.svg';
import cycle from '../../assets/images/cycle.svg';
import school from '../../assets/images/school.svg';
import park from '../../assets/images/park.svg';
import transit from '../../assets/images/transit.svg';
import profile from '../../assets/images/profile.png';

import axios from 'axios';
import { Carousel, Modal } from 'react-bootstrap';
import Listing from '../Listing';
import Similar from './Similar';
const ListingSingle = (props) => {

    const{id}=useParams()
const navigate=useNavigate()

    const [isMobile, setIsMobile] = useState(false)
    const [isSingleDevice,setIsSingleDevice]=useState(false)
const[mainImage,setMainImage]=useState(null)
const[similar,setSimilar]=useState([])
const [slideIndex,setSLideIndex]=useState()
const [main,setMain]=useState()
const [check,setCheck]=useState(false)
const[list,setList]=useState({})
const[images,setImages]=useState([])
const [formData, setFormData] = useState({
    listingOptions: '',
    areaOptions: '',
    budget: '',
    traits: '',
    firstname: '',
    isLead:1,
    lastname: "",

    address1: "",
    isLead:true,
   
    provinceName: "",
    source: "Website",
    phone: "",
    parentId: null,
    createdAt: "",
    updatedAt: "",
    realtorId: null,
    propertyId: null,
    children: [],
    email: '',
    phone: '',
  });
  const [showSlider, setShowSlider] = useState(false);
  const [show, setShow] = useState(false);

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide:slideIndex
  };
  const handleDont = () => setShowSlider(false);
  const handleShow = () => setShowSlider(true);

  const statusOptions = [
    { id: 1, name: "Active", color: "#1A8B17" },
    { id: 2, name: "Foreclosures", color: "red" },
    { id: 3, name: "For Rent", color: "blue" },
    { id: 4, name: "For Sale", color: "#e09200" },
    { id: 5, name: "Lease", color: "orange" },
    { id: 6, name: "New Construction", color: "#9d5a04" },
    { id: 7, name: "New Listing", color: "pink" },
    { id: 8, name: "Open House", color: "teal" },
    { id: 9, name: "Reduced Price", color: "indigo" },
    { id: 10, name: "Resale", color: "lime" },
    { id: 11, name: "Sold", color: "#A91212" },
    { id: 12, name: "Land", color: "gray" },
    { id: 13, name: "Owner Occupied", color: "olive" },
  ];

  const handleResize = () => {

      if (window.innerWidth < 576){
        setIsSingleDevice(true)
      }
      else{
        setIsSingleDevice(false)
      }
      if (window.innerWidth < 767 && window.innerWidth >= 576) {
          setIsMobile(true)
      } else {
          setIsMobile(false)
      }
    }
    
    // create an event listener
    useEffect(() => {
      window.addEventListener("resize", handleResize)
   
    },[])

    const getStatus=(status)=>{
    
      if(status==8){
        return statusOptions[0].name
      }
      
          return statusOptions[status-1].name
        }
        const getColor=(status)=>{
      
          if(status==8){
            return statusOptions[0].color
          }
          return statusOptions[status-1].color
        }
  useEffect(() => {
   
  getListingdata()
  
      
  }, [props,id]);
  const reduceReviews = (acc, cur, index) => {
    let groupSize = 8; // Number of items in a group for desktop
    if (isMobile) {
      groupSize = 4; // Number of items in a group for mobile
    }
  
    const groupIndex = Math.floor(index / groupSize);
    if (!acc[groupIndex]) acc[groupIndex] = [];
    acc[groupIndex].push(cur);
    return acc;
  };
  
  const getListingdata=async()=>{
    const res = await axios.get("https://24krealty.ca/public/api/property")
    console.log(res.data,"sfsff")
    const listed = res.data.find(x => x.id == id);
    const filtered=props.listings.filter((list)=>list.id!=id)
    setSimilar(filtered)
    setList(listed);
    console.log(listed)
    const imagesnew = JSON.parse(listed.images);
    
    const mainImageIndex = imagesnew.findIndex(image => image === listed.mainImage);


console.log(mainImageIndex);
    setImages(imagesnew)
setMain(mainImageIndex)
   setSLideIndex(mainImageIndex)
    }



    

  const url = process.env.REACT_APP_API_URL;
  const[formSubmitted,setFormSubmitted]=useState(false)
    const [validationErrors, setValidationErrors] = useState({

      name: '',
     
    });
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setValidationErrors({
          
      })
      setFormData({ ...formData, [name]: value });
    };
    const handleSubmit =async (e) => {
      e.preventDefault();
  
      const errors = {};
  
     
      if (!formData.firstname) {
        errors.firstname = 'Name is required';

      }
  
   
  
     
  
      if (Object.keys(errors).length > 0) {
          console.log(errors)
        setValidationErrors(errors);
      } else {
          const response = await axios.post(`${url}api/contacts/create`, {...formData,message:`Hello, I am interested in ${list.address}`
        
        })
        // Form data is valid, you can perform any further actions here
        console.log('Form data submitted:', formData);

        setFormData({
          
              listingOptions: '',
              areaOptions: '',
              budget: '',
              traits: '',
              firstname: '',
              isLead:1,
              message:'',
              lastname: "",
              birthDate: "",
              address1: "",
              isLead:true,
              address2: "",
              city: "",
              provinceName: "",
              source: "",
              phone: "",
              parentId: null,
              createdAt: "",
              updatedAt: "",
              realtorId: null,
              propertyId: null,
              children: [],
              email: '',
              phone: '',
            
        })
        setFormSubmitted(true)

      }
    };

  


  return (

    <div className='single-page-wrapper'>
        <div className='signle-page-top-bar'>
            <div className='container-main'>
                <div className='listing-page-top-bar-bread-crums'>
                    <div className='breadcrums-wrapper'>
                      <Link className='breadcrum-txt' to='/'>Home</Link> <p></p> <Link className='breadcrum-txt' to='/featured-listing'>Feature Listing</Link>  <p></p> <p>{list?.mls_no}</p>
                    </div>

                    <div className='back-btn-page'>
                    <Button onClick={()=>navigate(-1)}><img src={back}/>Back to Results</Button>
                    </div>
                </div>
            </div>
        </div>

        <div className='individual-page-top-two'>
             <div className='container-main'>
                <div className='single-page-top-bar-wrapper'>
                    <div className='property-btm-config-wraper'>
                        <div className='signle-page-top-bar-left'>
                            <h3 className='property-mls-no'>{list?.mls_no}</h3>
                            <div className='signle-page-top-bar-left-btm'>
                              <p className='property-address property-address-single'><img src={location}/>{list?.address}</p>    
                              <h3 className='property-mls-no'>${list?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</h3>
                            </div>
                        </div>
                    </div>

                    <div className='signle-page-top-bar-right signle-page-top-bar-right-icons'>
                        <p className='property-address property-address-single'><img src={bed} />{list?.bedrooms} Bedroom</p>
                        <p className='property-address property-address-single'><img src={paper} />{list?.bathrooms} Bathroom</p>
                        <p className='property-address property-address-single'><img src={area}/>{list?.squareFeet} Home Area</p>
                        <p className='property-address property-address-single'><img src={calendar}/>{list?.completionDate?.slice(0,4)} Year Build</p>
                    </div>
                </div>

             </div>
        </div>

        <div className='signle-page-middle'>
                <div className='signle-page-middle-wrapper'>
                    <div className='single-page-middle-left'>
                        <div className='signle-page-main-img'>
                           <img  style={{cursor:"pointer"}} onClick={() => {
                                              setSLideIndex(main)
                                              handleShow();
                                           
                                          
                                            }} className='' src={mainImage?mainImage:list?.mainImage} />
                        </div>
        <Modal show={showSlider} onHide={handleDont} dialogClassName="modal-full-img modal-90w"> 
        <button className='full-img-cross' onClick={handleDont}>x</button>                
        <Modal.Body>
          <Slider {...settings}>
            {images.map((image, index) => (
              <div key={index}>
                <img src={image} alt={`Slide ${index + 1}`} style={{ width: '100%' }} />
              </div>
            ))}
          </Slider>
        </Modal.Body>
        </Modal>
                        <div className='signle-page-thumb-wraper'>
                            <Carousel>
                                {images.length > 0 &&
                                    images.reduce(reduceReviews, []).map((imagearr, index) => (
                                    <Carousel.Item key={index}>
                                        <div className='slider-images'>
                                        {imagearr.map((image, innerIndex) => (
                                        
                                        <img
                                            key={innerIndex}
                                            onClick={() => {
                                              if(isMobile){
                                                setSLideIndex(innerIndex+(index*4))
                                              }
                                              else{
                                                setSLideIndex(innerIndex+(index*8))
                                              }
                                              
                                              handleShow();
                                           
                                          
                                            }}
                                            className='' // Add your class here
                                            src={image}
                                            alt={`Slide ${innerIndex}`}
                                        />
                                    
                                        ))}
                                        </div>
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                        </div>
                    </div>
                </div>
        </div>

        <div className='featured-listing-page-two-columns'>
        <div className='container-main container-main-about-sec-tw'>
         <div className='featured-listing-page-left-column'>
            <div className='property-description-wrap'>
                <div className='property-discription-part-wrapper'>
                    <div className='property-discription-part'>
                        <p>Status:</p>
                        {list && list.status && (
                            <span className='property-status property-status-single-page' style={{ backgroundColor: getColor(list.status) }}>
                                {getStatus(list.status)}
                            </span>
                        )} 
                    </div>

                    <div className='property-discription-part'>
                        <p>Property Type</p>
                        <p className='big'>{list?.propertyType} </p>
                    </div>

                    <div className='property-discription-part'>
                        <p>MLS® #</p>
                        <p className='big'>{list?.mls_no} </p>
                    </div>
                </div>

                <div className='property-discription-part-wrapper property-discription-part-wrapper-next'>
                    <div className='property-discription-part'>
                        <p>Sq. Feet:</p>
                        <p className='big'>{list?.squareFeet} </p>
                    </div>

                    <div className='property-discription-part'>
                        <p>Listed on</p>
                        <p className='big'>{list?.createdAt?.toString().slice(0,10)} </p>
                    </div>

                    <div className='property-discription-part'>
                        <p>Price</p>
                        <p className='big'>${list?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</p>
                    </div>
                </div>

                <div className='single-page-discription' dangerouslySetInnerHTML={{__html: list?.description}}/>
            
                <div className='about-property'>
                  <h5>About {list?.mls_no}</h5>
                  <p>{list?.address} is located in Surrey and has a current listing price of ${list?.price?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}. This {list?.squareFeet}  square foot apartment/condo for sale has {list?.bedrooms} bedrooms and {list?.bathrooms} baths.

More details about {list?.address} are below as entered by the listing brokerage, including property taxes, year built, assigned schools, type of construction, exterior and interior features, locations, views, and neighbourhood amenities in Guildford.

To find your Surrey area dream home, call 24k Realty Group of eXp Realty! Our local agents are experts in the Surrey real estate market and are committed to providing outstanding service. Speak with an agent at 24k Realty Group of eXp Realty at 778.592.4312 to tour MLS #{list?.mls_no}today.</p>
                </div>

                <div className='inner-map-sec'>
                   <h5>Detailed Map </h5>
                   {list?.address?.length>0&&<iframe class="map-iframe" src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyD4gdwMtdQU2b9nFXNwlSjFsiXDvuo1AmQ&q=${list?.address}`} width="100%" height="400" allowfullscreen="" loading="lazy"></iframe>}
                </div>

                <div className='propert-details-se-wrp'>
                  <div className='propert-details-sec'>
                      <div className='inner-map-sec'>
                        <h5>COMMUNITY INFORMATION</h5>
                        <div className='infonew-table'>
                          <p><span>Address</span> {list?.address}</p>
                        </div>
                      </div>

                      <div className='inner-map-sec'>
                        <h5>FEATURES / AMENITIES</h5>
                        <div className='infonew-table'>  
                          <p><span>Interior Features:</span> {list?.interiorFeatures || "N/A"}</p>
                          <p><span>Amenities:</span> {list?.amenities || "N/A"}</p>
                          <p><span>Heating:</span> {list?.heating || "N/A"}</p>
                        </div>
                      </div>

                      <div className='inner-map-sec'>
                        <h5>TAX AND FINANCIAL INFO</h5>
                        <div className='infonew-table'>  
                          <p><span>Gross Tax:</span> {list?.grossTax || "N/A"}</p>
                          <p><span>For Tax Year:</span> {list?.taxYear || "N/A"}</p>
                          <p><span>Maintenance Fee:</span> {list?.maintenanceFee || "N/A"}</p>
                        </div>
                      </div>

                      <div className='inner-map-sec'>
                        <h5>ARCHITECTURE</h5>
                        <div className='infonew-table'>  
                          <p><span>Bedrooms:</span> {list?.bedrooms}</p>
                          <p><span>Bathrooms:</span> {list?.bathrooms}</p>
                          <p><span>Square Foot:</span> {list?.squareFeet}</p>
                          <p><span>Stories:</span> {list?.storeysInBuilding || "N/A"}</p>
                          <p><span>Style:</span> {list?.style || "N/A"}</p>
                          <p><span>Storeys in Building:</span> {list?.storeysInBuilding || "N/A"}</p>
                          <p><span>Parking:</span> {list?.parking || "N/A"}</p>
                          <p><span>Parking Places:</span> {list?.parkingPlaces || "N/A"}</p>
                        </div>
                      </div>

                      <div className='inner-map-sec'>
                        <h5>PROPERTY FEATURES</h5>
                        <div className='infonew-table'>  
                          <p><span>Lot Dimensions:</span> {list?.lotDimensions || "N/A"}</p>
                          <p><span>View:</span> {list?.view || "N/A"}</p>
                          <p><span>Lot Features:</span> {list?.lotFeatures || "N/A"}</p>
                        </div>
                      </div>
                  </div>

                  <div className='property-auth'>
                    <img src={profile}/> <p>Kunal Bharatendu </p>
                  </div>
                </div>
            </div>

            <div className='walking-and-transportaion-wrapper schools-parks-wrapperr'>
                <div className='productlisting-wrapper similar-listings-wrapper'>
                  <div className='second-sec-txt-wrap'>
                      <h2>SCHOOLS & PARKS</h2>
                  </div>

                  <div className='walking-and-transportaion-point-wrapper schools-parks-wrapper'>
                    <div className='walking-and-transportaion-point'>
                        <div className='icon-with-text'>
                          <img src={school}/>
                        </div>
                        <p>6 public & 6 Catholic schools serve this home. Of these, 2 have catchments. There are 3 private schools nearby.</p>
                    </div>

                    <div className='walking-and-transportaion-point'>
                        <div className='icon-with-text'>
                          <img src={park}/>
                        </div>
                        <p>6 sports fields, 4 ball diamonds and 8 other facilities are within a 20 min walk of this home.</p>
                    </div>

                    <div className='walking-and-transportaion-point'>
                        <div className='icon-with-text'>
                          <img src={transit}/>
                        </div>
                        <p>Street transit stop less than a 2 min walk away. Rail transit stop less than 3 km away.</p>
                    </div>
                  </div>

                </div>
            </div>

            <div className='walking-and-transportaion-wrapper'>
                <div className='productlisting-wrapper similar-listings-wrapper'>
                  <div className='second-sec-txt-wrap'>
                      <h2>WALKING AND TRANSPORTATION</h2>
                  </div>

                  <div className='walking-and-transportaion-point-wrapper'>
                    <div className='walking-and-transportaion-point'>
                        <div className='icon-with-text'>
                          <img src={walk}/>
                          <h6>74</h6>
                        </div>
                        <h5>VERY WALKABLE</h5>
                    </div>

                    <div className='walking-and-transportaion-point'>
                        <div className='icon-with-text'>
                          <img src={bus}/>
                          <h6>49</h6>
                        </div>
                        <h5>GOOD TRANSIT</h5>
                    </div>

                    <div className='walking-and-transportaion-point'>
                        <div className='icon-with-text'>
                          <img src={cycle}/>
                          <h6>75</h6>
                        </div>
                        <h5>VERY BIKEABLE</h5>
                    </div>
                  </div>

                </div>
            </div>

            <div className='similar-properties'>
              {similar.length>0&& <Similar listings={similar}/>}
            </div>
         </div>

         <div className='featured-listing-page-right-column'>
               <div className='featured-listingcolumn-top'>
                   <div className='featured-listingcolumn-header featured-listingcolumn-header-center'>
                      <img src='/i.svg'/>
                       <h4>Agent Connect</h4>
                   </div>

                   <div className='featured-listingcolumn-footer-wrapper featured-listingcolumn-footer-wrapper-btn'>
                      <div className='featured-listingcolumn-footer'>
                        <h6>Need more info?</h6>
                        <button onClick={()=>setShow(true)} className='ask-question-btn'>Ask a Question</button>
                      </div>
                   </div>
                </div>
                <Modal show={show} onHide={handleClose}>
                <Modal.Body>
                {!formSubmitted? <div className='single-page-right-form'>
                     <div className='listing-form-top'>
                            <div className='listing-form-top-cnt'>
                                  <h5>Request More Info About This Listing</h5>
                                  <button onClick={handleClose}>x</button>
                            </div>
                     </div>

                     <div className='listing-form-top listing-form-top-sec'>
                            <div className='listing-form-top-cnt'>
                                  <h6>Complete the form below to request more info about this listing.</h6>
                            </div>
                     </div>

                     <div className='all-form-meterial'>

                            <Form className='listing-form'>
                                <div className='form-half-column-wrapper'>
                                    <div className='form-half-column'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="text" 
                                            
                                            value={formData.firstname}
                                            onChange={handleInputChange}
                                            name="firstname"
                                            placeholder="Name" />
                                        </Form.Group>
                                    </div>

                                    <div className='form-half-column'>
                                        <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                            <Form.Control type="tel" 
                                                name="phone"
                    
                                                value={formData.phone}
                                                onChange={handleInputChange}
                                            
                                            placeholder="Phone" />
                                        </Form.Group>
                                    </div>
                                </div>
                            
                            
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control type="email" 
                                   name="email"
                                   value={formData.email}
                                   onChange={handleInputChange}   

                                placeholder="Email" />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control as="textarea" 
                                name="message"
                                value={`Hello, I am interested in ${list.address}`}
                                onChange={handleInputChange}   
                                rows={3}
                         />
                            </Form.Group>
                                <Form.Select aria-label="Default select example">
                                <option>Contact Preference</option>
                                <option value="1">Phone</option>
                                <option value="2">Email</option>
                                </Form.Select>
                            </Form>

                            <div className='listing-form-check'>
                                {["I consent to receive emails containing real estate related information from this site's operators. I understand that I may unsubscribe at any time."].map((type) => (
                                    <div key={`default-${type}`} className="mb-3">
                                    <Form.Check 
                                        checked={check}
                                        onClick={()=>setCheck(!check)}
                                        type="checkbox"
                                        id={`default-${type}`}
                                        label={`${type}`}
                                    />
                                    </div>
                                ))}
                      </div>

                      <Button  onClick={handleSubmit} type="submit" className='listing-form-btn'>Request Info <img className='arrow-n-btn' src={arrowlong} alt="My Image" /></Button>
                    </div>
                </div>:

                <div className='form-submit-message'>
                <img className='tick-img' src='/tick.png' alt="My Image" />
                <p>Form Submitted successfully</p>
                </div>
                }
                </Modal.Body>
                </Modal>
              
         </div>

         </div>
      </div>
        
    </div> 



  
  )
}

export default ListingSingle